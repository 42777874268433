import {ProfilePropertyRepresentation} from '@aztrix/sdk';

export interface PropertyStateModel extends Partial<ProfilePropertyRepresentation> {
  modelStatus: 'pending' | 'ready' | 'error';
}

export interface PropertiesStateModel {
  profileId: string | undefined;
  properties: {[propertyId: string]: PropertyStateModel};
}

export const defaults: PropertiesStateModel = {
  profileId: undefined,
  properties: {},
};
