import {Component, Input, TemplateRef, ViewChild} from '@angular/core';

@Component({
  selector: 'ax-option',
  templateUrl: './option.component.html',
})
export class OptionComponent {
  static _nextId = 0;

  @Input() icon: string;
  @Input() value: number | string = OptionComponent._nextId++;

  @ViewChild(TemplateRef, {static: true}) _implicitContent: TemplateRef<unknown>;
}
