import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import {startWith} from 'rxjs';

import {OptionComponent} from './option/option.component';

@Component({
  selector: 'ax-option-panel',
  templateUrl: './option-panel.component.html',
  styleUrls: ['./option-panel.component.scss'],
})
export class OptionPanelComponent implements AfterContentInit {
  @ContentChildren(OptionComponent, {descendants: true}) _children: QueryList<OptionComponent>;

  _options: QueryList<OptionComponent> = new QueryList<OptionComponent>();

  @Input() selected: number | string | undefined = undefined;
  @Output() selectedChanged = new EventEmitter<number | string>();

  changeOption(option: number | string) {
    this.selected = option;
    this.selectedChanged.next(this.selected);
  }

  ngAfterContentInit(): void {
    this._children.changes.pipe(startWith(this._children)).subscribe((children) => {
      if (children.length) {
        this._options = children;
      }
    });
  }
}
