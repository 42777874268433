import {Component, Input} from '@angular/core';
import {ButtonComponent} from '@aztrix/components/button';
import {FeatureType, MatomoService} from '@aztrix/helpers';

@Component({
  selector: 'al-upgrade-tier-button',
  templateUrl: './upgrade-tier-button.component.html',
  styleUrls: ['./upgrade-tier-button.component.scss'],
})
export class UpgradeTierButtonComponent extends ButtonComponent {
  @Input() featureType: FeatureType | null;

  constructor(private _matomo: MatomoService) {
    super();
  }

  matomoEvent() {
    this._matomo.trackEvent('upgrade_tier', 'UPGRADE_' + this.featureType);
  }
}
