import {Selector} from '@ngxs/store';

import {PropertyStateModel} from './properties.model';
import {PropertiesState} from './properties.state';

// @dynamic
export class PropertiesStateQuery {
  @Selector([PropertiesState.propertyStateModels])
  static propertyById(properties: {
    [propertyId: string]: PropertyStateModel;
  }): (id: string) => PropertyStateModel {
    return (id: string) => properties[id] || {modelStatus: 'error'};
  }

  @Selector([PropertiesState.propertyStateModels])
  static properties(properties: {[propertyId: string]: PropertyStateModel}): PropertyStateModel[] {
    return Object.values(properties);
  }
}
