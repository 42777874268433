import {Component, EventEmitter, Output} from '@angular/core';

@Component({
  selector: 'al-tier-warning',
  templateUrl: './tier-warning.component.html',
  styleUrls: ['./tier-warning.component.scss'],
})
export class TierWarningComponent {
  @Output() cancel = new EventEmitter<void>();
  @Output() upgrade = new EventEmitter<void>();
}
