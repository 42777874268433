<div class="options">
  <button
    *ngFor="let option of _options"
    class="option"
    [ngClass]="{selected: option.value === selected}"
    (click)="changeOption(option.value)"
    axButton
    icon-button
  >
    <ax-icon class="icon" [name]="option.icon"></ax-icon>
  </button>
</div>

<ng-container *ngIf="_options | selectedOption: selected as option">
  <div class="content" *ngIf="option._implicitContent">
    <ng-container *ngTemplateOutlet="option._implicitContent"></ng-container>
  </div>
</ng-container>
