import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {ButtonModule} from '@aztrix/components/button';
import {IconsModule} from '@aztrix/icons';

import {OptionPanelComponent} from './option-panel.component';
import {OptionComponent} from './option/option.component';
import {SelectedOptionPipe} from './selected-option.pipe';

@NgModule({
  declarations: [OptionComponent, OptionPanelComponent, SelectedOptionPipe],
  imports: [CommonModule, IconsModule, ButtonModule],
  exports: [OptionPanelComponent, OptionComponent],
})
export class OptionPanelModule {}
