<a
  axButton
  class="upgrade-button"
  [color]="color"
  [filled]="_filled"
  [outline]="_outline"
  smallButton
  [fullWidth]="_fullWidth"
  [routerLink]="['account', 'billing', 'tiers']"
  [relativeTo]="null"
  (click)="matomoEvent()"
>
  <ax-icon class="icon" name="lock"></ax-icon>
  <span>{{ 'label.upgrade' | translate }}</span>
</a>
