import {Clear} from '@aztrix/list/store/actions';
import {AListService} from '@aztrix/list/store/alist/alist.service';
import {AListStateModel} from '@aztrix/list/store/alist/alist-state.model';
import {Injectable} from '@angular/core';
import {AbstractControl, Validators} from '@angular/forms';
import {AuthService} from '@aztrix/auth';
import {colorOrDefault} from '@aztrix/helpers';
import {
  AlistAppearanceRepresentation,
  AlistItemRepresentation,
  AlistItemWithItemsRepresentation,
  AlistItemWithPropertiesRepresentation,
  AlistItemWithPropertyRepresentation,
  AlistItemWithStringRepresentation,
  AlistLanguageRepresentation,
  AlistPropertyRepresentation,
  AlistRepresentation,
  AlistSettingsAnalyticsRepresentation,
  AlistSettingsRepresentation,
  AlistSubPropertyRepresentation,
} from '@aztrix/sdk';
import {ControlsOf, FormArray, FormControl, FormGroup} from '@ngneat/reactive-forms';
import {Actions, ofActionDispatched} from '@ngxs/store';
import {
  BehaviorSubject,
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  filter,
  first,
  map,
  merge,
  NEVER,
  Observable,
  shareReplay,
  switchMap,
  tap,
} from 'rxjs';

@Injectable({providedIn: 'root'})
export class FormService {
  private _form$ = new BehaviorSubject(new FormArray<AlistRepresentation>([]));

  clientAlistForm$ = combineLatest([
    this._form$,
    this._alist.activeAlistId$.pipe(distinctUntilChanged()),
  ]).pipe(
    map(([form, alistId]) => {
      return form.controls.find((control) => control.get('id')?.value === alistId);
    }),
    shareReplay(1)
  );

  clientAlistActiveForm$: Observable<FormControl<boolean | undefined> | undefined> =
    this.clientAlistForm$.pipe(
      map((form) => form?.get('active')),
      shareReplay(1)
    );

  clientAlistLanguageForm$ = combineLatest([
    this.clientAlistForm$,
    this._alist.activeAlistLanguageCode$.pipe(distinctUntilChanged()),
  ]).pipe(
    map(([form, languageCode]) => {
      if (!form) {
        return undefined;
      }
      return form
        .get('languages')
        ?.controls.find((control) => control.get('language')?.value === languageCode);
    }),
    shareReplay(1)
  );

  appearanceForm$ = this.clientAlistLanguageForm$.pipe(
    map((form) => <FormGroup<AlistAppearanceRepresentation>>form?.get('appearance')),
    shareReplay(1)
  );

  itemsForm$ = this.clientAlistLanguageForm$.pipe(
    map((form) => <FormArray<AlistItemRepresentation>>form?.get('items')),
    shareReplay(1)
  );

  settingsForm$ = this.clientAlistLanguageForm$.pipe(
    map((form) => form?.get('settings')),
    shareReplay(1)
  );

  analyticsForm$ = this.settingsForm$.pipe(
    map((settingsForm) => <FormGroup<any>>settingsForm?.get('analytics')),
    shareReplay(1)
  );

  clearCompleted$ = this._actions$.pipe(ofActionDispatched(Clear), shareReplay(1));

  constructor(
    private _alist: AListService,
    private _auth: AuthService,
    private _actions$: Actions
  ) {
    this._auth.authToken$
      .pipe(
        switchMap((auth) => {
          if (auth) {
            return this._alist.serverAlists$.pipe(
              map((alists) => alists.filter((alist) => alist.modelStatus === 'ready')),
              filter((alists) => !!alists?.length),
              first(),
              tap((alists) => {
                this._form$.next(this._createAlistsForm(alists));
              })
            );
          } else {
            this._form$.next(new FormArray([]));
            return NEVER;
          }
        })
      )
      .subscribe();

    this._form$
      .pipe(
        switchMap((form) => {
          if (form) {
            const observables: Observable<AlistLanguageRepresentation>[] = form.controls.map(
              (alistLanguageForm: AbstractControl) => alistLanguageForm.valueChanges
            );
            return merge(...observables).pipe(
              map((alistLanguage) => {
                const currentForm: AbstractControl =
                  form.controls.find(
                    (alistLanguageForm: AbstractControl) =>
                      alistLanguageForm.get('id')?.value === alistLanguage.id
                  ) ?? new FormGroup({});
                return currentForm?.getRawValue();
              })
            );
          } else {
            return NEVER;
          }
        }),
        debounceTime(200)
      )
      .subscribe((value) => {
        this._alist.setClientAlist(value);
      });

    this.clearCompleted$.subscribe(() => {
      this._form$.next(new FormArray([]));
    });
  }

  static createItemForm(
    item:
      | AlistItemWithItemsRepresentation
      | AlistItemWithPropertiesRepresentation
      | AlistItemWithPropertyRepresentation
      | AlistItemWithStringRepresentation
  ): FormGroup<ControlsOf<AlistItemRepresentation>> {
    return new FormGroup<ControlsOf<AlistItemRepresentation>>({
      id: new FormControl<string | undefined>(item.id),
      dataType: new FormControl<AlistItemWithStringRepresentation.DataTypeEnum | undefined>({
        value: item.dataType,
        disabled: false,
      }),
      type: new FormControl<
        | AlistItemWithStringRepresentation.TypeEnum
        | AlistItemWithPropertyRepresentation.TypeEnum
        | AlistItemWithPropertiesRepresentation.TypeEnum
        | undefined
      >({
        value: item.type,
        disabled: false,
      }),
      format: new FormControl<
        | AlistItemWithStringRepresentation.FormatEnum
        | AlistItemWithPropertyRepresentation.FormatEnum
        | AlistItemWithPropertiesRepresentation.FormatEnum
        | undefined
      >({
        value: item.format,
        disabled: false,
      }),
      title: new FormControl<string | undefined>(
        {value: item.title, disabled: false},
        item.type === AlistItemWithStringRepresentation.TypeEnum.HEADER ? Validators.required : null
      ),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      data: <any>FormService.createDataForm(item),
      icon: new FormControl<string | undefined>({value: item.icon, disabled: false}),
      visible: new FormControl<boolean>({value: item.visible || false, disabled: false}),
      orderIndex: new FormControl<number | undefined>(item.orderIndex, {nonNullable: true}),
    });
  }

  static createDataForm(item: AlistItemRepresentation) {
    if (item.dataType === AlistItemWithPropertiesRepresentation.DataTypeEnum.PROPERTIES) {
      const properties = item.data;
      return FormService.createItemPropertiesForm(<AlistPropertyRepresentation[]>properties);
    }
    if (item.dataType === AlistItemWithItemsRepresentation.DataTypeEnum.ITEMS) {
      const itemsForm = new FormArray<AlistItemWithItemsRepresentation>([]);
      const items = <AlistItemWithItemsRepresentation[]>item.data;
      for (const itm of items) {
        itemsForm.push(
          <FormGroup<ControlsOf<AlistItemWithItemsRepresentation>>>FormService.createItemForm(itm)
        );
      }
      return itemsForm;
    }
    if (item.dataType === AlistItemWithPropertyRepresentation.DataTypeEnum.PROPERTY) {
      return new FormControl<AlistPropertyRepresentation>({
        value: <AlistPropertyRepresentation>item.data,
        disabled: false,
      });
    }
    return new FormControl<string>({
      value: <string>item.data,
      disabled: false,
    });
  }

  static createItemPropertiesForm(properties: AlistPropertyRepresentation[]) {
    const propertiesForm = new FormArray<AlistPropertyRepresentation>([]);

    if (!properties?.length) {
      return propertiesForm;
    }

    for (const property of properties) {
      if (property?.properties?.length) {
        propertiesForm.push(
          new FormGroup<ControlsOf<AlistPropertyRepresentation>>({
            value: new FormControl(property.value || undefined),
            type: new FormControl(property.type),
            properties: FormService.createItemSubPropertyForm(property.properties),
          })
        );
      } else {
        propertiesForm.push(
          new FormGroup<ControlsOf<AlistPropertyRepresentation>>({
            value: new FormControl(property.value || undefined),
            type: new FormControl(property.type),
          })
        );
      }
    }
    return propertiesForm;
  }

  static createItemSubPropertyForm(subProperties: AlistSubPropertyRepresentation[]) {
    const subPropertiesForm = new FormArray<AlistSubPropertyRepresentation>([]);

    if (!subProperties?.length) {
      return subPropertiesForm;
    }

    for (const property of subProperties) {
      subPropertiesForm.push(
        new FormGroup<ControlsOf<AlistSubPropertyRepresentation>>({
          value: new FormControl(property.value || undefined),
          type: new FormControl(property.type),
        })
      );
    }
    return subPropertiesForm;
  }

  resetActiveForm() {
    this._alist.serverAlist$
      .pipe(
        first(),
        switchMap((alist) =>
          this.clientAlistForm$.pipe(
            map((alistForm) => alistForm?.reset(alist, {emitEvent: false}))
          )
        )
      )
      .subscribe();
  }

  updateForm() {
    this._alist.serverAlists$
      .pipe(
        map((alists) => alists.filter((alist) => alist.modelStatus === 'ready')),
        filter((alists) => !!alists?.length),
        first()
      )
      .subscribe((alists) => this._form$.next(this._createAlistsForm(alists)));
  }

  addLanguageForm(language: AlistLanguageRepresentation) {
    return this.clientAlistForm$.pipe(
      first(),
      map((form) => form?.get('languages')?.push(this._createLanguageForm(language)))
    );
  }

  removeLanguage(index: number) {
    this.clientAlistForm$
      .pipe(
        first(),
        map((form) => {
          const languagesForm = <FormArray<AlistLanguageRepresentation>>form?.get('languages');
          return languagesForm.removeAt(index);
        })
      )
      .subscribe();
  }

  removeAlist(index: number) {
    this._form$
      .pipe(
        first(),
        map((form) => form.removeAt(index))
      )
      .subscribe();
  }

  alistForm$(alistId: string) {
    return this._form$.pipe(
      map((form) => {
        return form.controls.find(
          (control: FormGroup<ControlsOf<AlistRepresentation>>) =>
            control.get('id')?.value === alistId
        );
      }),
      filter((form) => !!form),
      shareReplay(1)
    );
  }

  typeItemForm(
    itemFrom: FormGroup<ControlsOf<AlistItemRepresentation>>
  ):
    | FormGroup<ControlsOf<AlistItemWithItemsRepresentation>>
    | FormGroup<ControlsOf<AlistItemWithPropertiesRepresentation>>
    | FormGroup<ControlsOf<AlistItemWithPropertyRepresentation>>
    | FormGroup<ControlsOf<AlistItemWithStringRepresentation>> {
    const itemValue = itemFrom.value;
    switch (itemValue.type) {
      case AlistItemWithPropertyRepresentation.TypeEnum.PROPERTY:
        return <FormGroup<ControlsOf<AlistItemWithPropertyRepresentation>>>itemFrom;
      case AlistItemWithPropertyRepresentation.TypeEnum.PROPERTIES:
        return <FormGroup<ControlsOf<AlistItemWithPropertiesRepresentation>>>itemFrom;
      case AlistItemWithPropertyRepresentation.TypeEnum.PRODUCT_CARD:
      case AlistItemWithItemsRepresentation.TypeEnum.ITEMS:
        return <FormGroup<ControlsOf<AlistItemWithItemsRepresentation>>>itemFrom;
      default:
        return <FormGroup<ControlsOf<AlistItemWithStringRepresentation>>>itemFrom;
    }
  }

  private _createAlistsForm(alists: AListStateModel[]) {
    return new FormArray<AlistRepresentation>(
      <FormGroup<ControlsOf<AlistRepresentation>>[]>(
        alists.map((alist) => this._createAlistForm(alist))
      )
    );
  }

  private _createAlistForm(alist: AListStateModel) {
    return new FormGroup({
      id: new FormControl<string | undefined>({value: alist.id || undefined, disabled: false}),
      name: new FormControl<string | undefined>({value: alist.name, disabled: false}),
      active: new FormControl<boolean>({value: alist.active || false, disabled: false}),
      ownerId: new FormControl<string | undefined>({value: alist.ownerId, disabled: false}),
      domain: new FormControl<string | undefined>({value: alist.domain, disabled: false}),
      domainEnabled: new FormControl<boolean>({
        value: alist.domainEnabled || false,
        disabled: false,
      }),
      type: new FormControl<AlistRepresentation.TypeEnum>({
        value: alist.type || AlistRepresentation.TypeEnum.BIO,
        disabled: false,
      }),
      languages: this._createLanguagesForm(alist),
    });
  }

  private _createLanguagesForm(alist: AListStateModel) {
    const languagesForm = new FormArray<AlistLanguageRepresentation>([]);

    if (!alist.languages?.length) {
      return languagesForm;
    }

    for (const alistLanguage of alist.languages) {
      languagesForm.push(this._createLanguageForm(alistLanguage));
    }
    return languagesForm;
  }

  private _createLanguageForm(
    alistLanguage: AlistLanguageRepresentation
  ): FormGroup<ControlsOf<AlistItemRepresentation>> {
    return new FormGroup({
      id: new FormControl<string | undefined>({value: alistLanguage.id, disabled: false}),
      language: new FormControl<string | undefined>({
        value: alistLanguage.language || 'en',
        disabled: false,
      }),
      orderIndex: new FormControl<number | undefined>({
        value: alistLanguage.orderIndex,
        disabled: false,
      }),
      avatar: new FormControl<string | undefined>({value: alistLanguage.avatar, disabled: false}),
      title: new FormControl<string | undefined>({value: alistLanguage.title, disabled: false}),
      description: new FormControl<string | undefined>({
        value: alistLanguage.description,
        disabled: false,
      }),
      appearance: this._createAppearanceForm(alistLanguage.appearance),
      items: this._createItemsForm(alistLanguage.items),
      settings: this._createSettingsForm(alistLanguage.settings),
    });
  }

  private _createItemsForm(items?: AlistItemRepresentation[]) {
    const itemsForm = new FormArray<AlistItemRepresentation>([]);

    if (!items?.length) {
      return itemsForm;
    }

    for (const item of items) {
      itemsForm.push(
        <FormGroup<ControlsOf<AlistItemWithItemsRepresentation>>>FormService.createItemForm(item)
      );
    }
    return itemsForm;
  }

  private _createAppearanceForm(appearance?: AlistAppearanceRepresentation) {
    return new FormGroup({
      font: new FormControl<string>({
        value: appearance?.font || 'lato',
        disabled: false,
      }),
      backgroundImage: new FormControl<string | undefined>({
        value: appearance?.backgroundImage,
        disabled: false,
      }),
      backgroundImageEnabled: new FormControl<boolean>({
        value: appearance?.backgroundImageEnabled || false,
        disabled: false,
      }),
      backgroundColor: new FormControl<string>({
        value: colorOrDefault(appearance?.backgroundColor, '#00ABC2'),
        disabled: false,
      }),
      textColor: new FormControl<string>({
        value: colorOrDefault(appearance?.textColor, '#363F48'),
        disabled: false,
      }),
      buttonColor: new FormControl<string>({
        value: colorOrDefault(appearance?.buttonColor, '#00ABC2'),
        disabled: false,
      }),
      buttonFontColor: new FormControl<string>({
        value: colorOrDefault(appearance?.buttonFontColor, '#FFFFFF'),
        disabled: false,
      }),
      whitelabel: new FormControl<boolean>({
        value: appearance?.whitelabel ?? false,
        disabled: false,
      }),
    });
  }

  private _createSettingsForm(settings?: AlistSettingsRepresentation) {
    return new FormGroup({
      analytics: this._createAnalyticsForm(settings?.analytics),
    });
  }

  private _createAnalyticsForm(analytics: AlistSettingsAnalyticsRepresentation | undefined) {
    const facebook = analytics?.facebook;
    const google = analytics?.google;
    const facebookActive = facebook?.active || false;
    const googleActive = google?.active || false;
    return new FormGroup({
      facebook: new FormGroup({
        active: new FormControl(facebook?.active || false),
        pixelId: new FormControl({value: facebook?.pixelId, disabled: !facebookActive}, [
          Validators.required,
        ]),
        apiAccessToken: new FormControl(
          {value: facebook?.apiAccessToken, disabled: !facebookActive},
          [Validators.required]
        ),
      }),
      google: new FormGroup({
        active: new FormControl(google?.active || false),
        analyticsId: new FormControl({value: google?.analyticsId, disabled: !googleActive}, [
          Validators.required,
        ]),
        utmMedium: new FormControl({value: google?.utmMedium, disabled: !googleActive}),
        utmSource: new FormControl({value: google?.utmSource, disabled: !googleActive}),
      }),
    });
  }
}
