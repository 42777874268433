import {Inject, Injectable, Optional} from '@angular/core';
import {AUTH_TOKEN} from '@aztrix/sdk';
import {Store} from '@ngxs/store';
import {BehaviorSubject, Observable} from 'rxjs';

import {FetchCurrentProfileProperties} from './properties.actions';
import {PropertyStateModel} from './properties.model';
import {PropertiesStateQuery} from './properties.query';
import {PropertiesState} from './properties.state';

@Injectable({providedIn: 'root'})
export class PropertiesService {
  profileId$: Observable<string | undefined> = this._store.select(PropertiesState.profileId);
  properties$: Observable<PropertyStateModel[]> = this._store.select(
    PropertiesStateQuery.properties
  );

  constructor(
    private _store: Store,
    @Optional() @Inject(AUTH_TOKEN) authToken: BehaviorSubject<string | undefined>
  ) {
    authToken.subscribe(() => {
      this._store.dispatch(FetchCurrentProfileProperties);
    });
  }
}
