import {Injectable} from '@angular/core';
import {isOfType} from '@aztrix/helpers';
import {ContextTypeRepresentation, ProfileService} from '@aztrix/sdk';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {tap} from 'rxjs';

import {Clear} from '../actions';
import {FetchCurrentProfileProperties} from './properties.actions';
import {defaults, PropertiesStateModel, PropertyStateModel} from './properties.model';

@State<PropertiesStateModel>({
  name: 'properties',
  defaults,
})
@Injectable()
export class PropertiesState {
  constructor(private _profileService: ProfileService) {}

  @Selector()
  static propertyStateModels(state: PropertiesStateModel): {
    [propertyId: string]: PropertyStateModel;
  } {
    return state?.properties ?? defaults.properties;
  }

  @Selector()
  static profileId(state: PropertiesStateModel): string | undefined {
    return state?.profileId || undefined;
  }

  @Action(Clear)
  clear({setState}: StateContext<PropertiesStateModel>) {
    setState({...defaults});
  }

  @Action(FetchCurrentProfileProperties)
  fetchCurrentProfileProperties({patchState, getState}: StateContext<PropertiesStateModel>) {
    return this._profileService.getMeProfile('FULL').pipe(
      tap((profile) => {
        const newProperties = profile.propertyContexts
          ?.filter(
            (context) =>
              !isOfType(
                context,
                ContextTypeRepresentation.NameEnum.GROUP,
                ContextTypeRepresentation.NameEnum.PROPOSAL,
                ContextTypeRepresentation.NameEnum.MISC
              )
          )
          .flatMap((c) => c.properties)
          .reduce(
            (object, property) => {
              object[property?.id || ''] = {...property, modelStatus: 'ready'};
              return object;
            },
            <{[propertyId: string]: PropertyStateModel}>{}
          );

        patchState({
          profileId: profile.id,
          properties: {
            ...getState().properties,
            ...newProperties,
          },
        });
      })
    );
  }
}
