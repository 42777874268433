import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {ButtonModule} from '@aztrix/components/button';
import {IconsModule} from '@aztrix/icons';
import {TranslatePipe} from '@aztrix/translate';

import {UpgradeTierButtonComponent} from './upgrade-tier-button.component';

@NgModule({
  declarations: [UpgradeTierButtonComponent],
  imports: [CommonModule, IconsModule, ButtonModule, RouterModule, TranslatePipe],
  exports: [UpgradeTierButtonComponent],
})
export class UpgradeTierButtonModule {}
