import {Pipe, PipeTransform, QueryList} from '@angular/core';

import {OptionComponent} from './option/option.component';

@Pipe({name: 'selectedOption'})
export class SelectedOptionPipe implements PipeTransform {
  transform(
    options: QueryList<OptionComponent>,
    selected?: number | string
  ): OptionComponent | undefined {
    return options.find((option) => option.value === selected);
  }
}
